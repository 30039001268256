import React from "react";
import { graphql } from "gatsby";
import { Container } from "reactstrap";
import { Form, Field, FormSpy } from "react-final-form";
import * as yup from "yup";
import { Button } from "react-bootstrap";
import Layout from "../../../layouts/index";
import Seo from "../../../components/Seo";
import IntroBanner from "../../../components/IntroBannerRevamp";
import Banner from "../../../images/services/software/banner.jpg";
import SvgIconsSet from "../../../components/SvgIconsSet";
import { sendEmail } from "../../../services/contact";
import { generateDownload } from "../../../utils/helperFunctions";

// Images
import BannerUX from "../../../images/services/software/banner-ux.jpg";
import BannerWebdesign from "../../../images/services/software/banner-webdesign.jpg";
import BannerArchitect from "../../../images/services/software/banner-architect.jpg";
import BannerCloud from "../../../images/services/software/banner-cloud.png";
import BannerFullstack from "../../../images/services/software/banner-fullstack.jpg";
import Bannerlongterm from "../../../images/services/support/banner-longterm.jpg";

// Process
import OurProcess from "../../../components/services/OurProcess";

// Casestudy
import downloadFile from "../../../documents/Case-Study-Customised-Software-Development.pdf";
import downloadFile2 from "../../../documents/Case-Study-Customised-gigaclear-Astro HR.pdf";
import caseStudyImgOne from "../../../images/services/software/case-home-loan.jpg";
import caseStudyImgTwo from "../../../images/services/software/banner-crm.jpg";
import CasestudyTwo from "../../../components/CasestudyTwo";

// Contact
import GetInTouchbtn from "../../../components/GetInTouchbtn";
import BookConsultation from "../../../components/BookConsultation";

const SoftwareDevelopment = () => {
  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is a required field"),
  });

  const validate = (values) => {
    const errors = {};
    try {
      schema.validateSync(values, { abortEarly: false });
    } catch (err) {
      err.inner.forEach((error) => {
        errors[error.path] = error.message;
      });
    }
    return errors;
  };

  const onSubmitHandler = async (values) => {
    // eslint-disable-next-line no-undef
    generateDownload(document, [downloadFile, downloadFile2]);

    const formData = { ...values };
    formData.Message =
      "\nCASE STUDY 1: HOW THIS HOME LOAN PROVIDER GOT 60 LOAN ACQUISITIONS DAILY\nCASE STUDY 2: HOW AUTOMATING HR PROCESSES SKYROCKETED PRODUCTIVITY FOR GIGACLEAR";
    formData.FormType = "CaseStudy";
    formData.Subject = "CASE STUDY: SOFTWARE DEVELOPMENT";

    try {
      await sendEmail(formData);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  return (
    <Layout bodyClass="page-services custom-software">
      <Seo
        keyword="Software Development"
        title="Customised Software Development"
        description="Product Innovation and Application Lifecycle Management (ALM)
                are a significant part of iTelaSoft's key offerings. We have
                assisted a great variety of organisations, from start-ups to
                large enterprises, in their product lifecycle journey. Currently
                we support a number of customers around the world, including
                Australia, Europe, and North America."
      />

      <IntroBanner
        title="Customised Software Development"
        subtitle="Customise your solution to perfectly match your needs"
        image={Banner}
      />

      <div className="container pb-6 section-2">
        <div className="row pt-6 mb-3">
          <div className="col-12">
            <h2 className="title-1">Our Services</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 pr-md-5">
            <img
              alt="product innovation"
              className="img-fluid"
              src={BannerUX}
            />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">User Experience &amp; Design</h2>
            <p>
              <strong>
                Boost engagement and satisfaction with customer centric design.
                {" "}
              </strong>
              <br />
              We&apos;ll work with you to improve usability, increase user
              engagement and enhance your customer experience.
            </p>
          </div>
        </div>
        <div className="row pb-6">
          <div className="col-md-6 pr-md-5 order-md-2">
            <img
              alt="product innovation"
              className="img-fluid"
              src={BannerWebdesign}
            />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">
              Web Design &amp; Content Management Services
            </h2>
            <p>
              <strong>
                A well-structured website is the cornerstone of success.
                {" "}
              </strong>
              <br />
              We&apos;ll work with you to create a high-quality website that
              matches your needs, ensures ease of maintenance and maximises cost
              efficiency.
            </p>
          </div>
        </div>
        <div className="row pb-6">
          <div className="col-md-6 pr-md-5">
            <img
              alt="product innovation"
              className="img-fluid"
              src={BannerArchitect}
            />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">
              Platform Architecture &amp; Technical Design
            </h2>
            <p>
              <strong>
                Enable scalability and efficiency for your needs now and in the
                future.
                {" "}
              </strong>
              <br />
              We&apos;ll help you build a flexible and scalable framework that
              meets today&apos;s functional requirements and allows for ease of
              future enhancements. Seamless integration, process efficiency and
              a robust foundation come as standard in the architecture and
              design work we do.
            </p>
          </div>
        </div>
        <div className="row pb-6">
          <div className="col-md-6 pr-md-5 order-md-2">
            <img
              alt="product innovation"
              className="img-fluid"
              src={BannerCloud}
            />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">Cloud Engineering &amp; DevOps</h2>
            <p>
              <strong>Streamline your cloud investment. </strong>
              <br />
              We offer a range of cloud infrastructure and integration services
              to automate, streamline and enhance operations. We optimise your
              cloud investment to deliver improved efficiency and reduced costs.
            </p>
          </div>
        </div>
        <div className="row pb-6">
          <div className="col-md-6 pr-md-5">
            <img
              alt="product innovation"
              className="img-fluid"
              src={BannerFullstack}
            />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">Full Stack Development</h2>
            <p>
              <strong>Create a cohesive and fully integrated solution. </strong>
              <br />
              We offer both front-end and back-end development teams that work
              together seamlessly to deliver a fully functional and
              cost-effective solution.
            </p>
          </div>
        </div>
        <div className="getting-touch">
          <GetInTouchbtn textdce="Free consultation" />
        </div>
      </div>

      <Container>
        <OurProcess />
      </Container>

      <section>
        <CasestudyTwo
          image1={caseStudyImgOne}
          title1="How this home loan provider got 60 loan acquisitions daily"
          text1="A prominent home loan provider in Australia improved loan acquisition rates by 1,700% by integrating with a decisioning engine. Read how it was done."
          download1={null}
          image2={caseStudyImgTwo}
          title2="How automating HR processes skyrocketed productivity for Gigaclear"
          text2="Explore Gigaclear's HR process evolution alongside iTelaSoft, amplifying efforts to connect underserved communities with broadband."
          download2={null}
        />
      </section>

      <section className="pt-0">
        <div className="section-padding wrap-contrast-section download-portfolio consultation wrap-case-study">
          <Container>
            <div className="contrast-section">
              <div className="">
                <div className="">
                  <div className="">
                    <div className="row justify-content-center">
                      <div className="flex-column">
                        <div className="form-segment pb-3 mx-1">
                          <p>
                            <b>Download the Case Studies Now!</b>
                          </p>
                          <Form
                            onSubmit={onSubmitHandler}
                            validate={validate}
                            render={({ handleSubmit }) => (
                              <form
                                className="case-study-email-form d-flex flex-sm-row flex-column mb-1"
                                onSubmit={handleSubmit}
                              >
                                <Field name="email">
                                  {({ input, meta }) => (
                                    <div className="email-area col-lg-6 col-md-6 col-sm-6 col-12 px-0 pr-2">
                                      <input
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...input}
                                        type="email"
                                        placeholder="Enter your e-mail address"
                                      />
                                      {meta.error && meta.touched && (
                                        <span className="error-val">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                                <FormSpy
                                  subscription={{
                                    hasValidationErrors: true,
                                    submitting: true,
                                  }}
                                >
                                  {(subscriptions) => (
                                    <Button
                                      className={`btn-icon-button mt-1 mt-sm-0 mb-2 ${
                                        subscriptions.hasValidationErrors ||
                                        subscriptions.submitting
                                          ? "disabled-button"
                                          : ""
                                      }`}
                                      variant="primary"
                                      type="submit"
                                    >
                                      <span className="btn-text">
                                        Download Case Studies
                                      </span>
                                      <span className="btn-icon">
                                        <SvgIconsSet.SideArrow />
                                      </span>
                                    </Button>
                                  )}
                                </FormSpy>
                              </form>
                            )}
                          />
                          <p className="small-text">
                            By downloading these case studies, you agree to
                            receive emails from iTelaSoft about products,
                            services, and updates. You can unsubscribe at any
                            time.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>

      <Container className="my-6">
        <h2 className="title-1 mb-3">Book a free consultation</h2>
        <BookConsultation image={Bannerlongterm} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "cloud-services-1024x597.png" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default SoftwareDevelopment;
