import React from "react";
import { Container } from "reactstrap";
import SvgIconsSet from "./SvgIconsSet";

const CasestudyTwo = (props) => {
  const properties = { ...props };
  return (
    <div className="wrap-case-study-two mt-5 pt-5">
      <Container>
        <div className="pb-4">
          <div className="col-12">
            <div className="career">
              <div className="row">
                <div className="case-card col-12 col-md mb-md-0 mb-2">
                  <div className="row case-card-img">
                    <img
                      alt="product innovation"
                      className="img-fluid"
                      src={properties.image1}
                    />
                  </div>
                  <div className="row case-card-content">
                    <div>
                      <h3 className="title-3">{properties.title1}</h3>
                      <p>{properties.text1}</p>
                    </div>
                    <a
                      href={properties.download1}
                      download
                      className="btn btn-primary btn-icon-button mt-2 mb-3"
                      hidden={!properties.download1}
                    >
                      <span className="btn-text">Download Case Study</span>
                      <span className="btn-icon">
                        <SvgIconsSet.SideArrow />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="case-card col-12 col-md">
                  <div className="row case-card-img">
                    <img
                      alt="product innovation"
                      className="img-fluid"
                      src={properties.image2}
                    />
                  </div>
                  <div className="row case-card-content">
                    <div>
                      <h3 className="title-3">{properties.title2}</h3>
                      <p>{properties.text2}</p>
                    </div>
                    <a
                      href={properties.download2}
                      download
                      className="btn btn-primary btn-icon-button mt-2 mb-3"
                      hidden={!properties.download2}
                    >
                      <span className="btn-text">Download Case Study</span>
                      <span className="btn-icon">
                        <SvgIconsSet.SideArrow />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CasestudyTwo;
